export let common = {
  whoops: 'Whoops! Please try again.'
}

export let auth = {
  passwordChanged: 'Your password has been changed.',
  passwordResetRequested: {
    title: 'Password Reset Requested',
    message: 'We’ve sent you an email containing instructions to reset your account-password.'
  },
  passwordResetComplete: 'Your password has been reset, and you can now sign in.',
  hi: 'Welcome back',
  bye: 'Bye for now! 👋',
  loginFailed: 'Login failed. Please try again.',
  invalidCredentials: 'The email address and/or password you have provided are incorrect. Please try again.',
  missingCredentials: 'Please enter both your email address and password to sign in.',
  welcome: 'Welcome to ' + process.env.VUE_APP_NAME,
}

export let assetType = {


  confirmDelete: {
    title: 'Delete Asset Type',
    message: 'Are you sure you want to delete this Asset Type? Warning: This will delete all assets and related data.',
    confirmText: 'Delete'
  },
  created: 'Asset type created.',
  saved: 'Asset type saved.',
  deleted: 'Asset type deleted.',
  dynamicStatuses: 'Dynamic statuses are secondary statuses that can be applied to assets. Dynamic statuses are created against the asset type. The asset type can have multiple dynamic statuses. Each dynamic status must have one or more options. Once the dynamic status has been created, the active option can be set for each asset of the given asset type.'
}

export let checklist = {
  created: 'Checklist created.',
  saved: 'Checklist saved.'
}

export let check = {
  confirmDelete: {
    title: 'Delete Check',
    message: 'Are you sure you would like to delete this check? Warning: this may affect older inspections.',
    confirmText: 'Delete Check',
  },
  confirmRemove: {
    title: 'Remove Checkpoint',
    message: 'Are you sure you would like to remove this checkpoint?',
    confirmText: 'Remove Checkpoint',
  },
  added: 'Check added',
  removed: 'Check removed',
  created: 'Check created.',
  saved: 'Check saved.',
  deleted: 'Check deleted.',
}

export let client = {
  confirmDelete: {
    title: 'Delete Client',
    message: 'Are you sure you would like to delete this client? Warning: this action will also delete associated data, and cannot be undone without contacting support.',
    confirmText: 'Delete Client',
  },
  created: 'Client created.',
  logoUploaded: 'Client logo uploaded and set.',
  saved: 'Client saved.',
  deleted: 'Client and related data has been deleted.',
}

export let jobType = {
  confirmDelete: {
    title: 'Delete Job Type',
    message: 'Are you sure you want to delete this Job Type? Warning: This will delete all related data.',
    confirmText: 'Delete Job Type'
  },
  created: 'Job type created.',
  saved: 'Job type saved.',
  deleted: 'Job type deleted.'
}

export let assetGroup = {
  confirmDelete: {
    title: 'Delete Asset Group',
    message: 'Are you sure you want to delete this Asset Group? Warning: This will delete all related data.',
    confirmText: 'Delete Asset Group'
  },
  saved: 'Asset Group saved.',
  deleted: 'Asset Group deleted.'
}

export let job = {
  confirmDelete: {
    title: 'Delete Job',
    message: `<p>Are you sure you want to delete this Job?</p> <p><strong class="has-text-danger">Warning:</strong> <strong>This will delete all related data and will delete the job from the technicians device.</strong></p>`,
    confirmText: 'Delete Job'
  },
  saved: 'Job saved.',
  closed: 'Job closed.',
  opened: 'Job opened.',
  deleted: 'Job deleted.',
  signedAndClosed: 'Job Signed and Closed.',
}

export let site = {
  confirmDelete: {
    title: 'Delete Site',
    message: 'Are you sure you want to delete this Site? Warning: this action will also delete associated data, and cannot be undone without contacting support.',
    confirmText: 'Delete Site'
  },
  created: 'Site created.',
  saved: 'Site saved.',
  deleted: 'Site deleted.',
  logoUploaded: 'Site logo uploaded and set.'
}

export let zone = {
  confirmDelete: {
    title: 'Delete Zone',
    message: `Are you sure you want to delete this Zone?<br> <strong class="has-text-danger">Warning: this action will delete all associated data (assets, inspections), and cannot be undone without contacting support.</strong>`,
    confirmText: 'Delete Zone'
  },
  created: 'Zone created.',
  saved: 'Zone saved.',
  deleted: 'Zone deleted.',
}

export let user = {
  saved: 'User saved.'
}

export let support = {
  ticketOpened: 'Ticket opened – please check your email for confirmation.'
}

export let userPersona = {
  created: 'Persona created.',
  updated: 'Persona updated.'
}
