import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store/index'
import * as ModalDialogs from 'vue-modal-dialogs'
import { filters, stores, mixins } from '@/mixins/js-helpers'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import { post } from '@/api/request'
import './components/iot/widgets/install'
import './components/iot/values/install'

import '@/lang'

import VueApexCharts from 'vue-apexcharts'

Vue.component('apexchart', VueApexCharts)

import VueQRCodeComponent from 'vue-qr-generator'

Vue.component('qr-code', VueQRCodeComponent)
Vue.use(Croppa)

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDhskWS9vpInfVfPEHSWE1_vPkNt7X4sDk',
    libraries: 'places'
  }
})

import namingConvention from "@/mixins/naming-conventions/namingConvention";
import NamingEnum from "@/mixins/naming-conventions/namingConventionsEnum";
import Naming from "@/mixins/naming-conventions/namingConventionComputed";
import Convert from "@/mixins/naming-conventions/namingStringConverter";

Vue.use(namingConvention)
Vue.use(NamingEnum)
Vue.use(Naming)
Vue.use(Convert)

import VueGooglePlaces from 'vue-google-places'

Vue.use(VueGooglePlaces)

import Closeable from './directives/Closeable'
import PreventParentScroll from '@/utils/prevent-parent-scroll'
import InputAutowidth from "@/utils/input-autowidth";
Vue.directive('closable', Closeable)
Vue.directive('prevent-parent-scroll', PreventParentScroll)
Vue.directive('autowidth', InputAutowidth)

Vue.use(ModalDialogs)

const user = {
  name: 'Michael Kirk',
  key: 'michael-krp',
};

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas, far)
dom.watch()

Vue.use(filters)

import Toast from '@/mixins/toast'

Vue.use(Toast, {
  types: Toast.types,
  namespaced: true
})
import EasyRoute from '@/mixins/easy-route/index'
import './utils/IsAdmin'
import './utils/isCompanyPrimaryUser'
import admin from '@/routes/guards/admin'
import auth from '@/routes/guards/auth'
import activeSubscription from '@/routes/guards/active-subscription'
import passwordSet from '@/routes/guards/password-set'
import guest from '@/routes/guards/guest'
import decommissionAssets from '@/routes/guards/abilities/Asset/decommission-assets'
import createAssets from '@/routes/guards/abilities/Asset/create-assets'
import editAssets from '@/routes/guards/abilities/Asset/edit-assets'
import manageDocumentation from '@/routes/guards/abilities/Asset/manage-documentation'
import transferAssetsBetweenZones from '@/routes/guards/abilities/Asset/transfer-assets-between-zones'
import viewInspectionHistory from '@/routes/guards/abilities/Asset/view-inspection-history'
import viewDeletedInspectionHistory from '@/routes/guards/abilities/Asset/view-deleted-inspection-history'
import createClients from '@/routes/guards/abilities/Client/create-clients'
import deleteClients from '@/routes/guards/abilities/Client/delete-clients'
import editClients from '@/routes/guards/abilities/Client/edit-clients'
import manageClientSites from '@/routes/guards/abilities/Client/manage-client-sites'
import companyBilling from '@/routes/guards/abilities/Company/company-billing'
import editCompanyInformation from '@/routes/guards/abilities/Company/edit-company-information'
import manageAssetGroups from '@/routes/guards/abilities/Company/manage-asset-groups'
import manageAssetTypes from '@/routes/guards/abilities/Company/manage-asset-types'
import manageBarcodes from '@/routes/guards/abilities/Company/manage-barcodes'
import manageJobTypes from '@/routes/guards/abilities/Company/manage-job-types'
import assignCustomFieldTemplateToSites from '@/routes/guards/abilities/CustomFields/assign-custom-field-template-to-sites'
import createCustomFieldTemplates from '@/routes/guards/abilities/CustomFields/create-custom-field-templates'
import updateCustomFieldTemplates from '@/routes/guards/abilities/CustomFields/update-custom-field-templates'
import createCustomFields from '@/routes/guards/abilities/CustomFields/create-custom-fields'
import updateCustomFields from '@/routes/guards/abilities/CustomFields/update-custom-fields'
import manageDynamicStatuses from '@/routes/guards/abilities/DynamicStatus/manage-dynamic-statuses'
import viewCustomFieldTemplates from "@/routes/guards/abilities/CustomFields/view-custom-field-templates";
import viewTrashedCustomFieldTemplates from "@/routes/guards/abilities/CustomFields/view-trashed-custom-field-templates";
import closeJobs from '@/routes/guards/abilities/Job/close-jobs'
import createJobs from '@/routes/guards/abilities/Job/create-jobs'
import deleteJobs from '@/routes/guards/abilities/Job/delete-jobs'
import manageChecklists from '@/routes/guards/abilities/Job/manage-checklists'
import manageChecks from '@/routes/guards/abilities/Job/manage-checks'
import manageJobs from '@/routes/guards/abilities/Job/manage-jobs'
import createRecurringJobs from '@/routes/guards/abilities/RecurringJob/create-recurring-jobs'
import deleteRecurringJobs from '@/routes/guards/abilities/RecurringJob/delete-recurring-jobs'
import manageRecurringJobs from '@/routes/guards/abilities/RecurringJob/manage-recurring-jobs'
import createSites from '@/routes/guards/abilities/Site/create-sites'
import deleteSites from '@/routes/guards/abilities/Site/delete-sites'
import editSite from '@/routes/guards/abilities/Site/edit-site'
import manageAssets from '@/routes/guards/abilities/Site/manage-assets'
import siteManageDocumentation from '@/routes/guards/abilities/Site/manage-documentation'
import manageUserAccess from '@/routes/guards/abilities/Site/manage-user-access'
import manageZones from '@/routes/guards/abilities/Site/manage-zones'
import viewZones from '@/routes/guards/abilities/Site/view-zones'
import siteImportAssets from '@/routes/guards/abilities/Site/import-assets'
import transferZones from '@/routes/guards/abilities/Site/Zone/transfer-zones'
import createUsers from '@/routes/guards/abilities/User/create-users'
import deactivateUsers from '@/routes/guards/abilities/User/deactivate-users'
import editUsers from '@/routes/guards/abilities/User/edit-users'
import userManageDocumentation from '@/routes/guards/abilities/User/manage-documentation'
import manageDocuments from '@/routes/guards/abilities/User/manage-documents'
import manageNotifications from '@/routes/guards/abilities/User/manage-notifications'
import managePermissions from '@/routes/guards/abilities/User/manage-permissions'
import manageSiteAccess from '@/routes/guards/abilities/User/manage-site-access'
import upgradeDowngrade from '@/routes/guards/abilities/User/upgrade-downgrade'
import viewUsers from '@/routes/guards/abilities/User/view-users'
import importUsers from '@/routes/guards/abilities/User/import-users'
import viewDeletedJobs from '@/routes/guards/abilities/Job/view-deleted-jobs'
import viewDeletedClients from '@/routes/guards/abilities/Client/view-deleted-clients'
import pullFailedCheckpointsActivityReport from '@/routes/guards/abilities/Site/pull-failed-checkpoints-activity-report'

Vue.use(EasyRoute, {
  router,

  guards: [
    admin,
    auth,
    passwordSet,
    guest,
    activeSubscription,

    // TODO: We need to refactor and reduce these guards to be one guard which accepts the ability to look for.
    // Asset
    decommissionAssets,
    createAssets,
    editAssets,
    manageDocumentation,
    transferAssetsBetweenZones,
    viewInspectionHistory,
    viewDeletedInspectionHistory,

    // Client
    createClients,
    deleteClients,
    editClients,
    manageClientSites,
    viewDeletedClients,

    // Company
    companyBilling,
    editCompanyInformation,
    manageAssetGroups,
    manageAssetTypes,
    manageBarcodes,
    manageJobTypes,

    // CustomFields
    assignCustomFieldTemplateToSites,
    createCustomFieldTemplates,
    createCustomFields,
    updateCustomFields,
    updateCustomFieldTemplates,
    viewCustomFieldTemplates,
    viewTrashedCustomFieldTemplates,

    // Dynamic Statuses
    manageDynamicStatuses,

    // Job
    closeJobs,
    createJobs,
    deleteJobs,
    manageChecklists,
    manageChecks,
    manageJobs,
    viewDeletedJobs,

    // Recurring Jobs
    createRecurringJobs,
    deleteRecurringJobs,
    manageRecurringJobs,

    // Site
    createSites,
    deleteSites,
    editSite,
    manageAssets,
    siteManageDocumentation,
    manageUserAccess,
    manageZones,
    viewZones,
    siteImportAssets,
    pullFailedCheckpointsActivityReport,

    // Zone
    transferZones,

    // User
    createUsers,
    deactivateUsers,
    editUsers,
    userManageDocumentation,
    manageDocuments,
    manageNotifications,
    managePermissions,
    manageSiteAccess,
    upgradeDowngrade,
    viewUsers,
    importUsers,
  ]
})

Vue.use(stores, { store })

import scrollToTop from '@/utils/scroll-to-top'

Vue.prototype.$scrollToTop = scrollToTop


Vue.config.productionTip = process.env.VUE_APP_DEBUG

import { linkify } from '@/filters'

Vue.filter('linkify', linkify)

import Draggable from 'vuedraggable'
import namingStringConverter from "@/mixins/naming-conventions/namingStringConverter";

Vue.component('draggable', Draggable)

// import AdminTechnician from '@/views/dashboard/Technician'

// Common partials
Vue.component('widget', () => import('@/partials/Widget'))
Vue.component('no-data', () => import('@/partials/NoData'))

Vue.component('loader', () => import('@/partials/Loader'))
Vue.component('page', () => import('@/views/common/Page'))
Vue.component('level', () => import('@/views/common/Level'))
Vue.component('flickity', () => import('@/partials/Flickity'))
Vue.component('route', () => import('@/partials/Route'))
Vue.component('moment', () => import('@/partials/Moment'))
Vue.component('country-selector', () => import('@/partials/CountrySelector'))
Vue.component('split-view', () => import('@/partials/SplitView'))
Vue.component('expand-collapse', () => import('@/transitions/ExpandCollapse'))

// Custom Fields
Vue.component('custom-fields-draggable-box', () => import('@/partials/custom-fields/CustomFieldsDraggableBox'))
Vue.component('custom-fields-draggable-item', () => import('@/partials/custom-fields/CustomFieldsDraggableItem'))
Vue.component('custom-field-value-input', () => import('@/partials/custom-fields/CustomFieldValueInput'))
Vue.component('predefined-answer-create', () => import('@/partials/custom-fields/PredefinedAnswerCreate'))
Vue.component('predefined-answer-item', () => import('@/partials/custom-fields/PredefinedAnswerItem'))

// Data Selector Slots
Vue.component('data-selector-user-slot', () => import('@/partials/data-selector-slots/User.vue'))
Vue.component('data-selector-site-slot', () => import('@/partials/data-selector-slots/Site.vue'))
Vue.component('data-selector-risk-slot', () => import('@/partials/data-selector-slots/Risk.vue'))
Vue.component('data-selector-client-slot', () => import('@/partials/data-selector-slots/Client.vue'))
Vue.component('data-selector-persona-slot', () => import('@/partials/data-selector-slots/UserPersonaSlot.vue'))
Vue.component('data-selector-checklist-slot', () => import('@/partials/data-selector-slots/Checklist.vue'))
Vue.component('data-selector-dynamic-status-slot', () => import('@/partials/data-selector-slots/DynamicStatus.vue'))
// End Data Selector Slots

Vue.component('dashboard-admin', () => import('@/views/dashboard/Admin'))
Vue.component('link-site-manager', () => import('@/partials/links/SiteOverview'))
Vue.component('link-client-manager', () => import('@/partials/links/ClientOverview'))
Vue.component('link-asset-manager', () => import('@/partials/links/AssetOverview'))
Vue.component('link-zone-manager', () => import('@/partials/links/ZoneOverview'))
Vue.component('link-create-asset', () => import('@/partials/links/CreateAsset'))
Vue.component('link-job-handler', () => import('@/partials/links/JobHandler'))
Vue.component('link-user-overview', () => import('@/partials/links/UserOverview'))
Vue.component('link-user-overview-left', () => import('@/partials/links/UserOverviewLinkLeft'))
Vue.component('current-status', () => import('@/partials/CurrentStatus'))
Vue.component('next-inspection', () => import('@/partials/NextInspection'))
Vue.component('latest-inspection', () => import('@/partials/LatestInspection'))
Vue.component('component-icon', () => import('@/partials/ComponentIcon'))
Vue.component('template-item', () => import('@/views/custom-field-templates/partials/TemplateItem'))
Vue.component('arbitrary-status', () => import('@/partials/ArbitraryStatus'))
Vue.component('check-search', () => import('@/components/search/Check'))
Vue.component('profile-pic', () => import('@/partials/ProfilePicture'))
Vue.component('physical-address', () => import('@/partials/PhysicalAddress'))

Vue.component('side-bar-admin', () => import('@/components/nav/SideBar/Admin'))
Vue.component('side-bar-client', () => import('@/components/nav/SideBar/Client'))
Vue.component('side-bar-technician', () => import('@/components/nav/SideBar/Technician'))

Vue.component('doughnut-chart', () => import('@/components/graph/types/doughnut'))
Vue.component('bar-chart', () => import('@/components/graph/types/bar'))
Vue.component('pie-chart', () => import('@/components/graph/types/pie'))
Vue.component('line-chart', () => import('@/components/graph/types/line'))
Vue.component('intl-phone', () => import('@/partials/InternationalPhone'))
Vue.component('phone-num', () => import('@/partials/PhoneNumber'))

Vue.component('image-manager', () => import('@/partials/ImageManager'))

Vue.component('current-risk', () => import('@/partials/icons/CurrentRisk'))
Vue.component('check-circle', () => import('@/partials/icons/CheckCircle'))
Vue.component('warning-triangle', () => import('@/partials/icons/WarningTriangle'))

Vue.component('new-job-dropdown', () => import('@/partials/NewJobDropdown'))
Vue.component('table-actions-trigger', () => import('@/partials/TableActionsTrigger'))
Vue.component('summary-item', () => import('@/components/SummaryItem'))
Vue.component('select-custom-fields', () => import('@/partials/custom-fields/CustomFieldsDraggableItem'))

Vue.component('notification-channel-check', () => import('@/partials/NotificationChannelCheck'))
Vue.component('inspection-status', () => import('@/components/inspection/Status'))

//UI
Vue.component('action-button', () => import('@/components/ui/buttons/ActionButton'))
Vue.component('static-button', () => import('@/components/ui/buttons/StaticButton'))
Vue.component('submit-button', () => import('@/components/ui/buttons/SubmitButton'))
Vue.component('checkbox-group', () => import('@/components/ui/form/CheckboxGroup'))
Vue.component('checkbox-input', () => import('@/components/ui/form/CheckboxInput'))
Vue.component('data-selector', () => import('@/components/ui/form/DataSelector'))
Vue.component('server-data-selector', () => import('@/components/search/ServerDataSelector'))
Vue.component('date-picker', () => import('@/components/ui/form/DatePicker'))
Vue.component('field-error', () => import('@/components/ui/form/FieldError'))
Vue.component('field-label', () => import('@/components/ui/form/FieldLabel'))
Vue.component('file-input', () => import('@/components/ui/form/FileInput'))
Vue.component('formatted-text-input', () => import('@/components/ui/form/FormattedTextInput'))
Vue.component('markdown', () => import('@/components/ui/form/MarkdownEditor'))
Vue.component('masked-text-input', () => import('@/components/ui/form/MaskedTextInput'))
Vue.component('number-input', () => import('@/components/ui/form/NumberInput'))
Vue.component('password-input', () => import('@/components/ui/form/PasswordInput'))
Vue.component('select-input', () => import('@/components/ui/form/SelectInput'))
Vue.component('switch-input', () => import('@/components/ui/form/SwitchInput'))
Vue.component('text-area', () => import('@/components/ui/form/TextArea'))
Vue.component('text-input', () => import('@/components/ui/form/TextInput'))
Vue.component('text-input-list', () => import('@/components/ui/form/TextInputList'))
Vue.component('predefined-comments-table', () => import('@/components/ui/form/PredefinedCommentsTable'))

Vue.component('bs-icon', () => import('@/components/ui/icons/BsIcon'))
Vue.component('fa-icon', () => import('@/components/ui/icons/FaIcon'))
Vue.component('feather-icon', () => import('@/components/ui/icons/FeatherIcon'))
Vue.component('heroicon', () => import('@/components/ui/icons/Heroicon'))
Vue.component('icon', () => import('@/components/ui/icons/Icon'))
Vue.component('zondicon', () => import('@/components/ui/icons/Zondicon'))
Vue.component('base-modal', () => import('@/components/ui/third-party/BaseModal'))
Vue.component('avatar', () => import('@/components/ui/utilities/Avatar'))
Vue.component('content-wrapper', () => import('@/components/ui/utilities/ContentWrapper'))
Vue.component('dropdown-item', () => import('@/components/ui/utilities/DropdownItem'))
Vue.component('dropdown-wrapper', () => import('@/components/ui/utilities/DropdownWrapper'))
Vue.component('flyout', () => import('@/components/ui/utilities/Flyout'))
Vue.component('message', () => import('@/components/ui/utilities/Message'))
Vue.component('notification', () => import('@/components/ui/utilities/Notification'))
Vue.component('pager', () => import('@/components/ui/utilities/Pager'))
Vue.component('yes-no', () => import('@/components/ui/utilities/YesNo'))

router.beforeEach((to, from, next) => {
  store.commit('billow/error/clear')
  next()
})

router.onError(error => {
  console.log(error)
})

const vueConfig = {

  router,

  store,

  mixins: [mixins.errors],

  render: h => h(App),

  data: {
    version: process.env.VUE_APP_VERSION,
    base_url: process.env.VUE_APP_URL
  },

  methods: {
    hasAbility(abilityName, model) {
      return this.$store.getters['auth/user'].abilities.filter(ability => ability.name === abilityName && ability.entity_type === model).length > 0
    }
  },

  computed: {
    app() {
      return this.$children[0]
    }
  }
}

let boot = async () => {

  Promise.all([
    store.dispatch('auth/loadUser'),
    store.dispatch('company/load'),
    store.dispatch('namingConventions/getNamingConventions'),
    store.dispatch('list/loadCountries')
  ]).then(() => {
    new Vue(vueConfig).$mount('#app')
  }).catch(() => {
    new Vue(vueConfig).$mount('#app')
  })
}

boot()
